<template>
  <div class="device">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__deviceCertificateManagement") }}</h2>
      <div class="d-flex flex-wrap align-items-center justify-content-start justify-content-md-between">
        <div class="device-select me-2 mb-2">
          <select v-model="filterType" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__all") }}</option>
            <option :value="1">
              {{ $t("__bound") + $t("__agency") }}
            </option>
            <option :value="0">
              {{ $t("__unbound") + $t("__agency") }}
            </option>
          </select>
        </div>
        <div class="device-select me-2 mb-2">
          <select v-model="filterDeviceType" class="form-select rounded-pill me-2">
            <option value="all">{{ $t("__all") }}</option>
            <option value="glucose">
              {{ $t("__chilineDeviceGlucose") }}
            </option>
            <option value="oxygen">
              {{ $t("__chilineDeviceOxygen") }}
            </option>
            <option value="temperature">
              {{ $t("__foraDeviceForeheadTemperature") }}
            </option>
              <option value="ear_temperature">
              {{ $t("__foraDeviceEarTemperature") }}
            </option>
            <option value="pressure">
              {{ $t("__chilineDevicePressure") }}
            </option>
            <option value="ketone">
              {{ $t("__chilineDeviceKetone") }}
            </option>
            <option value="uric_acid">
              {{ $t("__chilineDeviceUricAcid") }}
            </option>
            <option value="cholesterol">
              {{ $t("__chilineDeviceCholesterol") }}
            </option>
          </select>
        </div>
        <div v-if="filterType !== 0" class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgency" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") + " ( " + $t('__agencyName') + ' / ' + $t('__codeName') + " )"}}</option>
              <option v-for="item in allAgencys" :value="item.id" :key="'agent'+ item.id">
                {{ item.name + ' / ' + item.codename }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2 me-auto mb-2">
          <form @submit.prevent="searchDevice">
            <div class="input-group rounded-pill">
              <input
                v-model="search"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchDeviceSerialId')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="search"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3">
        <div>
          <button
            v-show="permissions && permissions.some(permission => permission.name === 'chiline.create')"
            @click="showModal()"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="$t('__add')"
            class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2 mb-md-0"
          >
            <i class="bi bi-plus"></i>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            selectable
            :items="filterItem"
            :fields="fields"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            select-mode="multi"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            ref="selectableTable"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <template v-slot:cell(type)="data">
              <span v-if="data.item.type === 'glucose'">
                {{ $t('__chilineDeviceGlucose') }}
              </span>
              <span v-else-if="data.item.type === 'oxygen'">
                {{ $t('__chilineDeviceOxygen') }}
              </span>
              <span v-else-if="data.item.type === 'temperature'">
                {{ $t('__foraDeviceForeheadTemperature') }}
              </span>
                <span v-else-if="data.item.type === 'ear_temperature'">
                {{ $t('__foraDeviceEarTemperature') }}
              </span>
              <span v-else-if="data.item.type === 'pressure'">
                {{ $t('__chilineDevicePressure') }}
              </span>
              <span v-else-if="data.item.type === 'ketone'">
                {{ $t('__chilineDeviceKetone') }}
              </span>
              <span v-else-if="data.item.type === 'uric_acid'">
                {{ $t('__chilineDeviceUricAcid') }}
              </span>
              <span v-else-if="data.item.type === 'cholesterol'">
                {{ $t('__chilineDeviceCholesterol') }}
              </span>
              <span v-else>
                {{ $t('__chilineDeviceNoType') }}
              </span>
            </template>

            <template v-slot:cell(agency)="data">
              {{
                data.item.agency && data.item.agency ?
                data.item.agency.name + " / " + data.item.agency.codename :
                ""
              }}
            </template>

            <template v-slot:cell(resident)="data">
              {{
                data.item.resident && data.item.resident ?
                  data.item.resident.name :
                  ""
              }}
            </template>

            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  v-if="permissions && permissions.some(permission => permission.name === 'chiline.update')"
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
                <button
                  v-if="permissions && permissions.some(permission => permission.name === 'chiline.delete')"
                  @click="deleteDeviceAlert(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-danger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__delete')"
                >
                  <i class="bi bi-trash fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <select class="form-select rounded-pill me-2  mb-2" v-model="perPage">
            <option :value="10">
              {{ '顯示數量／頁：10' }}
            </option>
            <option :value="25">
              {{ '顯示數量／頁：25' }}
            </option>
            <option :value="50">
              {{ '顯示數量／頁：50' }}
            </option>
          </select>
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>

    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isEdit ? $t("__editDevice") : $t("__addDevice") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3" v-if="isSystemManage">
                <label for="MDeviceId" class="form-label fw-medium required">{{
                  $t("__foraDeviceId")
                }}</label>
                <input
                  v-model="modalData.MDeviceId"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg.MDeviceId
                  }"
                  id="MDeviceId"
                  required
                />

                <div class="invalid-feedback" v-if="validate && validate.msg.MDeviceId">
                  <div v-for="(msg, index) in validate.msg.MDeviceId" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="isSystemManage">
                <label for="agency_id" class="form-label fw-medium">{{
                    $t("__agencyName")
                  }}</label>

                <select
                  v-model="modalData.agency_id"
                  class="form-select"
                  id="editModal_manager_id"
                  :class="{
                    'is-invalid': validate && validate.msg.agency_id
                  }"
                >
                  <option v-for="agency in allAgencys" :value="agency.id" :key="'agent'+ agency.id">
                    {{ agency.name + ' / ' + agency.codename }}
                  </option>
                </select>

                <div class="invalid-feedback" v-if="validate && validate.msg.agency_id">
                  <div v-for="(msg, index) in validate.msg.agency_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>

              <div class="mb-3" v-if="isSystemManage">
                <label for="resident_id" class="form-label fw-medium">{{
                    $t("__foraResident")
                  }}</label>

                <select
                  v-model="modalData.resident_id"
                  class="form-select"
                  id="editModal_manager_id"
                  :class="{'is-invalid': validate && validate.msg.resident_id}"
                >
                  <option v-if="residents.length === 0" disabled>沒有住民資料</option>
                  <option v-for="resident in residents" :value="resident.id" :key="'resident'+ resident.id">
                    {{ resident.name }}
                  </option>
                </select>

                <div class="invalid-feedback" v-if="validate && validate.msg.resident_id">
                  <div v-for="(msg, index) in validate.msg.resident_id" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>

              <div class="mb-3" v-show="isSystemManage">
                <label for="editModal_manager_id" class="form-label fw-medium required">
                  {{ $t("__foraDeviceType") }}
                </label>

                <select
                  v-model="modalData.type"
                  class="form-select"
                  id="editModal_manager_id"
                  :class="{
                    'is-invalid': validate && validate.msg.type
                  }"
                >
                  <option key="glucose" value="glucose">
                    {{ $t("__chilineDeviceGlucose") }}
                  </option>
                  <option key="oxygen" value="oxygen">
                    {{ $t("__chilineDeviceOxygen") }}
                  </option>
                  <option key="temperature" value="temperature">
                    {{ $t("__foraDeviceForeheadTemperature") }}
                  </option>
                    <option key="ear_temperature" value="ear_temperature">
                    {{ $t("__foraDeviceEarTemperature") }}
                  </option>
                  <option key="pressure" value="pressure">
                    {{ $t("__chilineDevicePressure") }}
                  </option>
                  <option key="ketone" value="ketone">
                    {{ $t("__chilineDeviceKetone") }}
                  </option>
                 <option key="uric_acid" value="uric_acid">
                    {{ $t("__chilineDeviceUricAcid") }}
                  </option>
                  <option key="cholesterol" value="cholesterol">
                    {{ $t("__chilineDeviceCholesterol") }}
                  </option>
                </select>

                <div class="invalid-feedback" v-if="validate && validate.msg.type">
                  <div v-for="(msg, index) in validate.msg.type" :key="index">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="deleteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      ref="deleteModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h5 class="modal-title">{{ $t("__deleteDevice") }}</h5>
          </div>
          <div class="modal-body text-center">
            <p class="mb-0">{{ $t("__deleteDeviceNote", {Device: modalData.MDeviceId}) }}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-outline-secondary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t("__cancel") }}
            </button>
            <button
              @click="deleteDevice"
              type="button"
              class="btn btn-primary rounded-pill"
            >
              {{ $t("__confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { fora } from '@/http/api/fora.js'
import { agency } from '@/http/api/agency.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Fora',
  components: {},
  data () {
    return {
      sortBy: 'id',
      sortDesc: true,
      agencys: null,
      allAgencys: null,
      items: null,
      filterItem: null,
      filter: null,
      filterType: null,
      filterDeviceType: 'all',
      filterOn: ['Idno', 'MDeviceId'],
      search: null,
      searchAgency: null,
      searchAgent: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      modalData: {
        agency_id: null,
        resident_id: null,
        MDeviceId: null,
        type: null,
      },
      validate: null,
      residents: [],
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    permissions () {
      let permissions = null
      if (this.userInfo) {
        permissions = this.userInfo.permissions
      }
      return permissions
    },
    isSystemManage () {
      let systemManage = false
      if (this.permissions) {
        this.permissions.forEach((item) => {
          if (item.name === 'system-manage') {
            systemManage = true
          }
        })
      }
      return systemManage
    },
    fields () {
      return [
        {
          key: 'MDeviceId',
          label: i18n.t('__foraDeviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'type',
          label: i18n.t('__foraDeviceType'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agency',
          label: i18n.t('__agencyName') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'resident',
          label: i18n.t('__foraResident'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ]
    }
  },
  watch: {
    items (data) {
      const vm = this
      if (data) {
        vm.filterItem = vm.items
        vm.initBootstrapTooltip()
      }
    },
    filterItem (data) {
      if (data) {
        this.totalRows = data.length
      } else {
        this.totalRows = 0
      }
      this.currentPage = 1
    },
    filterType () {
      this.filterItems();
    },
    filterDeviceType () {
      this.filterItems();

    },
    searchAgency () {
      this.filterItems();

    },
    userInfo (data) {
      if (data) {
        this.modalData.manager_id = data.id
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    },
    'modalData.agency_id'() {
      this.residents = [];
      this.getResidents();
    },
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    getResidents () {
      agency.getResidents(this.modalData.agency_id, this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          this.residents = res.data.data;
        }
      })
    },
    getForaDevice () {
      const vm = this
      vm.Loading()
      fora
        .get('', vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    searchDevice () {
      this.filter = this.search
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.resetModalData()
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.forEach((item) => {
          if (item.id === id) {
            vm.modalData.agency_id = item.agency_id
            vm.modalData.resident_id = item.resident_id
            vm.modalData.MDeviceId = item.MDeviceId
            vm.modalData.type = item.type
          }
        })
      } else {
        vm.isEdit = false
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    resetModalData () {
      this.modalData.agency_id = null
      this.modalData.resident_id = null
      this.modalData.MDeviceId = null
      this.modalData.type = null
    },
    submitForm () {
      const vm = this

      vm.Loading()
      if (vm.editModalId) {
        fora
          .update(
            vm.editModalId,
            {
              agency_id: vm.modalData.agency_id,
              resident_id: vm.modalData.resident_id,
              MDeviceId: vm.modalData.MDeviceId,
              type: vm.modalData.type,
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getForaDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__editSuccess')
              })
              vm.bootstrap_modal.hide()
              vm.resetModalData()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else {
        fora
          .create(
            {
              agency_id: vm.modalData.agency_id,
              resident_id: vm.modalData.resident_id,
              MDeviceId: vm.modalData.MDeviceId,
              type: vm.modalData.type,
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              vm.getForaDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__createSuccess')
              })
              vm.bootstrap_modal.hide()
              vm.resetModalData()
            } else {
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Alerted()
            vm.Loaded()
          })
      }
    },
    deleteDeviceAlert (id) {
      const vm = this
      vm.editModalId = id
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.MDeviceId = item.MDeviceId
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.deleteModal)
      vm.bootstrap_modal.show()
      vm.$refs.deleteModal.addEventListener('hidden.bs.modal', () => {
        vm.getForaDevice()
      })
    },
    deleteDevice () {
      const vm = this
      console.log(vm.editModalId)
      fora
        .delete(
          vm.editModalId,
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__deleteDevice') + i18n.t('__success')
            })
            vm.getForaDevice()
            vm.resetModalData()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
          vm.Alerted()
          vm.Loaded()
          vm.bootstrap_modal.hide()
        })
    },
    filterItems() {
      this.filterItem = this.items.filter(item => {
        let matchesAgency = true;
        if (this.searchAgency !== null) {
          matchesAgency = item.device && item.device.agency_id === this.searchAgency;
        }

        let matchesType = true;
        switch (this.filterType) {
          case 0:
            matchesType = !item.device || !item.device.agency_id;
            break;
          case 1:
            matchesType = item.device && item.device.agency_id;
            break;
        }

        let matchesDeviceType = true;
        if (this.filterDeviceType !== 'all') {
          matchesDeviceType = item.type === this.filterDeviceType;
        }

        return matchesAgency && matchesType && matchesDeviceType;
      });
    }
  },
  created () {
    this.getForaDevice()
    this.getAllAgency()
  },
  mounted () {}
}
</script>

<style  lang="scss">
</style>
